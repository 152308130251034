import { defineStore } from "pinia";
import { TeamCategoryTypeEnum } from "@sgdf/ui-library";
import type { UserRole } from "./user";

interface UserState {
  meId: string | null;
  teamCategoryId: TeamCategoryTypeEnum;
  dailyTaskViewType: "all" | "me";
  exportParticipantStructureType: "territoire" | "groupe" | "all";
  roleByMeeting: Record<string, UserRole>;
  permissionByMeeting: Record<string, Set<string>>;
  userPermissions: Set<string>;
  isSuperUser: boolean;
  modulesByMeeting: Record<string, Set<string>>;
}

const init = {
  meId: null,
  teamCategoryId: TeamCategoryTypeEnum.DAILY_TASK,
  dailyTaskViewType: "me",
  exportParticipantStructureType: "territoire",
  roleByMeeting: {},
  permissionByMeeting: {},
  userPermissions: new Set<string>(),
  isSuperUser: false,
  modulesByMeeting: {},
} as const;

export const useUserStore = defineStore({
  id: "user-store",
  state: (): UserState => {
    return init;
  },
  actions: {
    setTeamCategory(value: TeamCategoryTypeEnum) {
      this.teamCategoryId = value;
    },
    setDailyTaskViewType(value: "all" | "me") {
      this.dailyTaskViewType = value;
    },
    setExportParticipantStructureType(value: "territoire" | "groupe" | "all") {
      this.exportParticipantStructureType = value;
    },
    setRole(role: UserRole, meetingId: string) {
      this.roleByMeeting = {
        ...this.roleByMeeting,
        [meetingId]: role,
      };
    },
    setMeetingPermissions(permissions: Set<string>, meetingId: string) {
      this.permissionByMeeting = {
        ...this.permissionByMeeting,
        [meetingId]: permissions,
      };
    },
    setModules(modules: Set<string>, meetingId: string) {
      this.modulesByMeeting = {
        ...this.modulesByMeeting,
        [meetingId]: modules,
      };
    },
    setUserPermissions(permissions: Set<string>) {
      this.userPermissions = permissions;
    },
    setSuperUser(isSuperUser: boolean) {
      this.isSuperUser = isSuperUser;
    },
    setMeId(id: string) {
      this.meId = id;
    }
  },

  getters: {
    getRoleByMeeting: (state) => {
      return (meetingId: string) => state.roleByMeeting[meetingId];
    },
    hasPermission: (state) => {
      return (permission: string, meetingId: string | null = null) => {
        if (state.isSuperUser) {
          return true;
        }

        if (meetingId) {
          return state.permissionByMeeting[meetingId!]?.has(permission) ?? false;
        }

        return state.userPermissions.has(permission);
      };
    },
    hasModule: (state) => {
      return (module: string, meetingId: string | null = null) => {
        return meetingId !== null && (state.modulesByMeeting[meetingId]?.has(module) ?? false);
      };
    },
  },
});
